(function ($) {

	"use strict";

	//Hide Loading Box (Preloader)
	function handlePreloader() {
		if ($('.preloader').length) {
			$('.preloader').delay(200).fadeOut(500);
		}
	}

	//Update Header Style and Scroll to Top
	function headerStyle() {
		if ($('.main-header').length) {
			var windowpos = $(window).scrollTop();
			var siteHeader = $('.main-header');
			var scrollLink = $('.scroll-to-top');
			if (windowpos >= 250) {
				siteHeader.addClass('fixed-header');
				scrollLink.fadeIn(300);
			} else {
				siteHeader.removeClass('fixed-header');
				scrollLink.fadeOut(300);
			}
		}
	}

	headerStyle();


	//Submenu Dropdown Toggle
	if ($('.main-header .navigation li.dropdown ul').length) {
		$('.main-header .navigation li.dropdown').append('<div class="dropdown-btn"><span class="fa fa-angle-down"></span></div>');

		//Dropdown Button
		$('.main-header .navigation li.dropdown .dropdown-btn').on('click', function () {
			$(this).prev('ul').slideToggle(500);
		});

		//Disable dropdown parent link
		$('.navigation li.dropdown > a').on('click', function (e) {
			e.preventDefault();
		});
	}


	//Custom Seclect Box
	if ($('.custom-select-box').length) {
		$('.custom-select-box').selectmenu().selectmenu('menuWidget').addClass('overflow');
	}

	//Date Picker
	if ($('input.date-time-picker').length) {
		jQuery('input.date-time-picker').datetimepicker();
	}

	//Close Schedule Form Popup / Inventory Single Page
	if ($('.hide-form-btn').length) {
		$('.hide-form-btn,.prod-thumbs-carousel .thumb-box').on('click', function (e) {
			e.preventDefault();
			$('.schedule-drive-outer').fadeOut(300);
		});
	}
	//Jquery Spinner / Quantity Spinner
	if ($('.quantity-spinner').length) {
		$("input.quantity-spinner").TouchSpin({
			verticalbuttons: true
		});
	}

	//Fact Counter + Text Count
	if ($('.count-box').length) {
		$('.count-box').appear(function () {

			var $t = $(this),
				n = $t.find(".count-text").attr("data-stop"),
				r = parseInt($t.find(".count-text").attr("data-speed"), 10);

			if (!$t.hasClass("counted")) {
				$t.addClass("counted");
				$({
					countNum: $t.find(".count-text").text()
				}).animate({
					countNum: n
				}, {
						duration: r,
						easing: "linear",
						step: function () {
							$t.find(".count-text").text(Math.floor(this.countNum));
						},
						complete: function () {
							$t.find(".count-text").text(this.countNum);
						}
					});
			}

		}, { accY: 0 });
	}


	//Two Item Carousel
	if ($('.two-item-carousel').length) {
		$('.two-item-carousel').owlCarousel({
			loop: true,
			margin: 30,
			nav: true,
			smartSpeed: 700,
			autoplay: 4000,
			navText: ['<span class="fa fa-angle-left"></span>', '<span class="fa fa-angle-right"></span>'],
			responsive: {
				0: {
					items: 1
				},
				600: {
					items: 1
				},
				800: {
					items: 1
				},
				1024: {
					items: 2
				},
				1200: {
					items: 2
				}
			}
		});
	}


	//Four Item Carousel
	if ($('.four-item-carousel').length) {
		$('.four-item-carousel').owlCarousel({
			loop: true,
			margin: 30,
			nav: true,
			smartSpeed: 700,
			autoplay: 4000,
			navText: ['<span class="fa fa-angle-left"></span>', '<span class="fa fa-angle-right"></span>'],
			responsive: {
				0: {
					items: 1
				},
				600: {
					items: 2
				},
				800: {
					items: 2
				},
				1024: {
					items: 3
				},
				1200: {
					items: 4
				}
			}
		});
	}


	//Tabs Box
	if ($('.tabs-box').length) {
		$('.tabs-box .tab-buttons .tab-btn').on('click', function (e) {
			e.preventDefault();
			var target = $($(this).attr('data-tab'));

			if ($(target).is(':visible')) {
				return false;
			} else {
				target.parents('.tabs-box').find('.tab-buttons').find('.tab-btn').removeClass('active-btn');
				$(this).addClass('active-btn');
				target.parents('.tabs-box').find('.tabs-content').find('.tab').fadeOut(0);
				target.parents('.tabs-box').find('.tabs-content').find('.tab').removeClass('active-tab');
				$(target).fadeIn(300);
				$(target).addClass('active-tab');
			}
		});
	}


	//Single Item Carousel
	if ($('.single-item-carousel').length) {
		$('.single-item-carousel').owlCarousel({
			loop: true,
			margin: 10,
			nav: true,
			smartSpeed: 700,
			autoplay: 5000,
			navText: ['<span class="fa fa-angle-left"></span>', '<span class="fa fa-angle-right"></span>'],
			responsive: {
				0: {
					items: 1
				},
				600: {
					items: 1
				},
				1200: {
					items: 1
				}
			}
		});
	}


	//Date Picker
	if ($('.datepicker').length) {
		$('.datepicker').datepicker();
	}


	// Hide / Show Invetory Detail Panels
	if ($('.inventory-details .details-panel-box .panel-header').length) {
		$(".inventory-details .details-panel-box .panel-header").on('click', function () {
			var target = $(this).next('.panel-content');
			$(target).slideToggle();

		});
	}


	//Accordion Box
	if ($('.accordion-box').length) {
		$(".accordion-box").on('click', '.acc-btn', function () {

			var outerBox = $(this).parents('.accordion-box');
			var target = $(this).parents('.accordion');

			if ($(this).hasClass('active') !== true) {
				$(outerBox).find('.accordion .acc-btn').removeClass('active');
			}

			if ($(this).next('.acc-content').is(':visible')) {
				return false;
			} else {
				$(this).addClass('active');
				$(outerBox).children('.accordion').removeClass('active-block');
				$(outerBox).find('.accordion').children('.acc-content').slideUp(300);
				target.addClass('active-block');
				$(this).next('.acc-content').slideDown(300);
			}
		});
	}


	//Three Item Carousel
	if ($('.three-item-carousel').length) {
		$('.three-item-carousel').owlCarousel({
			loop: true,
			margin: 30,
			nav: true,
			smartSpeed: 700,
			autoplay: 5000,
			navText: ['<span class="fa fa-angle-left"></span>', '<span class="fa fa-angle-right"></span>'],
			responsive: {
				0: {
					items: 1
				},
				600: {
					items: 1
				},
				778: {
					items: 2
				},
				800: {
					items: 2
				},
				1024: {
					items: 3
				},
				1200: {
					items: 3
				},
			}
		});
	}


	//Price Range Slider
	if ($('.price-range-slider').length) {
		$(".price-range-slider").slider({
			range: true,
			min: 0,
			max: 90,
			values: [8, 85],
			slide: function (event, ui) {
				$("input.property-amount").val(ui.values[0] + " - " + ui.values[1]);
			}
		});

		$("input.property-amount").val($(".price-range-slider").slider("values", 0) + " - $" + $(".price-range-slider").slider("values", 1));
	}


	//Testimonial Carousel
	if ($('.testimonial-carousel').length) {
		$('.testimonial-carousel').owlCarousel({
			loop: true,
			margin: 30,
			nav: true,
			smartSpeed: 700,
			autoplay: 5000,
			navText: ['<span class="fa fa-angle-left"></span>', '<span class="fa fa-angle-right"></span>'],
			responsive: {
				0: {
					items: 1
				},
				600: {
					items: 1
				},
				778: {
					items: 2
				},
				1024: {
					items: 3
				},
				1200: {
					items: 3
				},
			}
		});
	}

	// Product Carousel
	if ($('.prod-image-carousel').length && $('.prod-thumbs-carousel').length) {

		var $sync3 = $(".prod-image-carousel"),
			$sync4 = $(".prod-thumbs-carousel"),
			flag = false,
			duration = 500;

		$sync3
			.owlCarousel({
				loop: false,
				items: 1,
				margin: 10,
				nav: false,
				navText: ['<span class="fa fa-angle-left"></span>', '<span class="fa fa-angle-right"></span>'],
				dots: false,
				autoplay: false,
				autoplayTimeout: 5000
			})
			.on('changed.owl.carousel', function (e) {
				if (!flag) {
					flag = false;
					$sync4.trigger('to.owl.carousel', [e.item.index, duration, true]);
					flag = false;
				}
			});

		$sync4
			.owlCarousel({
				loop: false,
				margin: 15,
				items: 1,
				nav: false,
				navText: ['<span class="icon fa fa-angle-left"></span>', '<span class="icon fa fa-angle-right"></span>'],
				dots: false,
				center: false,
				autoplay: false,
				autoplayTimeout: 5000,
				responsive: {
					0: {
						items: 2,
						autoWidth: false
					},
					400: {
						items: 3,
						autoWidth: false
					},
					600: {
						items: 4,
						autoWidth: false
					},
					1024: {
						items: 4,
						autoWidth: false
					},
					1200: {
						items: 5,
						autoWidth: false
					}
				},
			})

			.on('click', '.owl-item', function () {
				$sync3.trigger('to.owl.carousel', [$(this).index(), duration, true]);
			})
			.on('changed.owl.carousel', function (e) {
				if (!flag) {
					flag = true;
					$sync3.trigger('to.owl.carousel', [e.item.index, duration, true]);
					flag = false;
				}
			});

	}


	//LightBox / Fancybox
	if ($('.lightbox-image').length) {
		$('.lightbox-image').fancybox({
			openEffect: 'fade',
			closeEffect: 'fade',
			helpers: {
				media: {}
			}
		});
	}


	//Contact Form Validation
	if ($('#contact-form').length) {
		$('#contact-form').validate({
			rules: {
				username: {
					required: true
				},
				email: {
					required: true,
					email: true
				},
				phone: {
					required: true
				},
				message: {
					required: true
				}
			}
		});
	}


	// Scroll to a Specific Div
	if ($('.scroll-to-target').length) {
		$(".scroll-to-target").on('click', function () {
			var target = $(this).attr('data-target');
			// animate
			$('html, body').animate({
				scrollTop: $(target).offset().top
			}, 1000);

		});
	}


	// Elements Animation
	if ($('.wow').length) {
		var wow = new WOW(
			{
				boxClass: 'wow',      // animated element css class (default is wow)
				animateClass: 'animated', // animation css class (default is animated)
				offset: 0,          // distance to the element when triggering the animation (default is 0)
				mobile: false,       // trigger animations on mobile devices (default is true)
				live: true       // act on asynchronously loaded content (default is true)
			}
		);
		wow.init();
	}

	/* ==========================================================================
	   When document is Scrollig, do
	   ========================================================================== */

	$(window).on('scroll', function () {
		headerStyle();
	});

	/* ==========================================================================
	   When document is loaded, do
	   ========================================================================== */

	$(window).on('load', function () {
		handlePreloader();
	});

})(window.jQuery);

// custom jquery for tabs 

$('.btnContinue').click(function () {
	var sexe = $("input[type='sexe']:checked").val();
	var nom = $("#nom").val();
	var prenom = $("#prenom").val();
	var email = $("#email").val();
	var phone = $("#phone").val();

	if (sexe !== '' && nom !== '' && prenom !== '' && email !== '' && phone !== '' && phone.length>=10) {
		$('.nav-tabs1 > .active').next('li').find('a').trigger('click');
		$("#message").focus();
	}
});

$('.annuler-btn').click(function () {
	$('.nav-tabs1>li>a').first().trigger('click');
	$('.nav-tabs1 li a').removeClass("active");
	$('#interset-form').trigger("reset");

});

$('.btnNext').click(function () {
	$('.nav-tabs1 > .active').next('li').find('a').trigger('click');
});

$('.btnPrevious').click(function () {
	$('.nav-tabs1 > .active').prev('li').find('a').trigger('click');
});

// custom jquery for forms

$(".input").focus(function () {
	$(this).parent().addClass("focus");
	$(this).hide().css("border-bottom-color", "#000;").fadeIn(500);

});
$(".input").focusout(function () {

	if ($(this).val().length > 1) {
		$(this).parent().addClass("focus");
	} else {
		$(this).parent().removeClass("focus");
		$(this).css("border-bottom-color", "#ddd;").fadeIn(500);
	}


})
// ".nav-tabs li:not('.active') a span"

$('#mobile-nav-ul a').on('shown.bs.tab', function () {
	$(this).children('span').addClass("rotate");
	console.log("tanbs");
	$("#mobile-nav-ul li:not('.active') a span").addClass("unrotate").removeClass("rotate");
});

